var pre = '/manage/';
export default {
  path: '/manage',
  title: '首营管理',
  header: 'home',
  custom: 'i-icon-erp i-icon-erp-jingying',
  auth: ['700', '701', '702'],
  children: [{
    custom: 'i-icon-erp i-icon-erp-chanpinguanli',
    path: "".concat(pre, "product"),
    auth: ['701'],
    title: '产品管理',
    children: [{
      path: "".concat(pre, "product/create"),
      title: '新增产品',
      auth: ['hidden']
    }, {
      path: "".concat(pre, "product/edit"),
      title: '修改产品信息',
      auth: ['hidden']
    }, {
      path: "".concat(pre, "product/detail"),
      title: '产品详情',
      auth: ['hidden']
    }]
  }, {
    custom: 'i-icon-erp i-icon-erp-gongsi',
    path: "".concat(pre, "company"),
    auth: ['702'],
    title: '企业管理',
    children: [{
      path: "".concat(pre, "company/create"),
      title: '新增机构',
      auth: ['hidden']
    }, {
      path: "".concat(pre, "company/edit"),
      title: '修改机构信息',
      auth: ['hidden']
    }, {
      path: "".concat(pre, "company/detail"),
      title: '机构详情',
      auth: ['hidden']
    }]
  }]
};