import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "D:/Projects/medical-web/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true,
  keepAlive: false
};
var pre = 'storage-';
export default {
  path: '/storage',
  name: 'storage',
  redirect: {
    name: "".concat(pre, "acceptance")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'in',
    name: "".concat(pre, "in"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '入库',
      closable: true,
      auth: ['302']
    }),
    component: function component() {
      return import('@/pages/storage/in');
    }
  }, {
    path: 'stock',
    name: "".concat(pre, "stock"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '当前库存',
      closable: true,
      auth: ['303']
    }),
    component: function component() {
      return import('@/pages/storage/stock');
    }
  }, {
    path: 'ex',
    name: "".concat(pre, "ex"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '出库',
      closable: true,
      auth: ['304']
    }),
    component: function component() {
      return import('@/pages/storage/ex');
    }
  }, {
    path: 'check',
    name: "".concat(pre, "check"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '盘点',
      closable: true,
      auth: ['305']
    }),
    component: function component() {
      return import('@/pages/storage/check');
    }
  }, {
    path: 'damaged',
    name: "".concat(pre, "damaged"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '报损',
      closable: true,
      auth: ['306']
    }),
    component: function component() {
      return import('@/pages/storage/damaged');
    }
  }, {
    path: 'move',
    name: "".concat(pre, "move"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '移库',
      closable: true,
      auth: ['307']
    }),
    component: function component() {
      return import('@/pages/storage/move');
    }
  }, {
    path: 'setup/warehouse',
    name: "".concat(pre, "setup-warehouse"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '仓库信息',
      closable: true,
      auth: ['320']
    }),
    component: function component() {
      return import('@/pages/storage/setup/warehouse');
    }
  }, {
    path: 'setup/loctype',
    name: "".concat(pre, "setup-loctype"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '库位类型',
      closable: true,
      auth: ['321']
    }),
    component: function component() {
      return import('@/pages/storage/setup/loctype');
    }
  }, {
    path: 'setup/location',
    name: "".concat(pre, "setup-location"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '库位',
      closable: true,
      auth: ['322']
    }),
    component: function component() {
      return import('@/pages/storage/setup/location');
    }
  }, {
    path: 'setup/record',
    name: "".concat(pre, "setup-record"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '湿温度记录',
      closable: true,
      auth: ['323']
    }),
    component: function component() {
      return import('@/pages/storage/setup/record');
    }
  }, {
    path: 'destruction',
    name: "".concat(pre, "destruction"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '销毁',
      closable: true,
      auth: ['309']
    }),
    component: function component() {
      return import('@/pages/storage/destruction');
    }
  }]
};