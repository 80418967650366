//
//
//
//
//
//
//
//
//
//
//
//
import iMenuSideItem from "./menu-item";
import iMenuSideTitle from "./menu-title";
import menuBadge from "../mixins/menu-badge";
export default {
  name: 'iMenuSideSubmenu',
  components: {
    iMenuSideItem: iMenuSideItem,
    iMenuSideTitle: iMenuSideTitle
  },
  mixins: [menuBadge],
  props: {
    menu: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  }
};