// 菜单，侧边栏
import dashboard from "./modules/dashboard";
import settings from "./modules/system";
import manage from "./modules/manage";
import shopping from "./modules/shopping";
import storage from "./modules/storage";
import sale from "./modules/sale";
import special from "./modules/special";
import finance from "./modules/finance";
import report from "./modules/report"; // 系统

import log from "./modules/log";
export default [dashboard, shopping, storage, sale, special, finance, manage, settings, report, log];