import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "D:/Projects/medical-web/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true,
  keepAlive: false
};
var pre = 'report-';
export default {
  path: '/report',
  name: 'report',
  redirect: {
    name: "".concat(pre, "acceptance")
  },
  meta: meta,
  component: BasicLayout,
  children: [// purchase
  {
    path: 'bpr/product',
    name: "".concat(pre, "bpr-product"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '首营产品档案',
      closable: true,
      auth: ['9101']
    }),
    component: function component() {
      return import('@/pages/report/bpr/product');
    }
  }, {
    path: 'bpr/company',
    name: "".concat(pre, "bpr-company"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '首营企业管理',
      closable: true,
      auth: ['9102']
    }),
    component: function component() {
      return import('@/pages/report/bpr/company');
    }
  }, {
    path: 'bpr/purchase',
    name: "".concat(pre, "bpr-purchase"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '采购记录',
      closable: true,
      auth: ['9103']
    }),
    component: function component() {
      return import('@/pages/report/bpr/purchase');
    }
  }, {
    path: 'bpr/accrecord',
    name: "".concat(pre, "bpr-accrecord"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '产品验收记录',
      closable: true,
      auth: ['9104']
    }),
    component: function component() {
      return import('@/pages/report/bpr/accrecord');
    }
  }, {
    path: 'bpr/inwarehouse',
    name: "".concat(pre, "bpr-inwarehouse"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '产品入库记录',
      closable: true,
      auth: ['9105']
    }),
    component: function component() {
      return import('@/pages/report/bpr/inwarehouse');
    }
  }, {
    path: 'bpr/expired',
    name: "".concat(pre, "bpr-expired"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '近效期产品催销表',
      closable: true,
      auth: ['9106']
    }),
    component: function component() {
      return import('@/pages/report/bpr/expired');
    }
  }, {
    path: 'bpr/salerecord',
    name: "".concat(pre, "bpr-salerecord"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '产品销售记录',
      closable: true,
      auth: ['9109']
    }),
    component: function component() {
      return import('@/pages/report/bpr/salerecord');
    }
  }, {
    path: 'bpr/outbound',
    name: "".concat(pre, "bpr-outbound"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '产品出库复核记录',
      closable: true,
      auth: ['9108']
    }),
    component: function component() {
      return import('@/pages/report/bpr/outbound');
    }
  }, {
    path: 'bpr/returnrecord',
    name: "".concat(pre, "bpr-returnrecord"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '销后退回产品验收记录',
      closable: true,
      auth: ['9110']
    }),
    component: function component() {
      return import('@/pages/report/bpr/returnrecord');
    }
  }, {
    path: 'bpr/check',
    name: "".concat(pre, "bpr-check"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '盘点记录',
      closable: true,
      auth: ['9107']
    }),
    component: function component() {
      return import('@/pages/report/bpr/check');
    }
  }, {
    path: 'bpr/after',
    name: "".concat(pre, "bpr-after"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '售后服务记录',
      closable: true,
      auth: ['9111']
    }),
    component: function component() {
      return import('@/pages/report/bpr/after');
    }
  }, {
    path: 'bpr/peer',
    name: "".concat(pre, "bpr-peer"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '随货同行单',
      closable: true,
      auth: ['9112']
    }),
    component: function component() {
      return import('@/pages/report/bpr/peer');
    }
  }, {
    path: 'qmr/quality',
    name: "".concat(pre, "qmr-quality"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '产品质量查询',
      closable: true,
      auth: ['9201']
    }),
    component: function component() {
      return import('@/pages/report/qmr/quality');
    }
  }, {
    path: 'qmr/complaint',
    name: "".concat(pre, "qmr-complaint"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '产品质量投诉处理记录',
      closable: true,
      auth: ['9202']
    }),
    component: function component() {
      return import('@/pages/report/qmr/complaint');
    }
  }, {
    path: 'qmr/unqualified',
    name: "".concat(pre, "qmr-unqualified"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '不合格产品处理汇总',
      closable: true,
      auth: ['9203']
    }),
    component: function component() {
      return import('@/pages/report/qmr/unqualified');
    }
  }, {
    path: 'qmr/frmloss',
    name: "".concat(pre, "qmr-frmloss"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '不合格产品确认及报损',
      closable: true,
      auth: ['9204']
    }),
    component: function component() {
      return import('@/pages/report/qmr/frmloss');
    }
  }, {
    path: 'qmr/track',
    name: "".concat(pre, "qmr-track"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '质量跟踪记录',
      closable: true,
      auth: ['9205']
    }),
    component: function component() {
      return import('@/pages/report/qmr/track');
    }
  }, {
    path: 'qmr/accident',
    name: "".concat(pre, "qmr-accident"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '质量事故报告记录',
      closable: true,
      auth: ['9206']
    }),
    component: function component() {
      return import('@/pages/report/qmr/accident');
    }
  }, {
    path: 'qmr/destroy',
    name: "".concat(pre, "qmr-destroy"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '销毁记录',
      closable: true,
      auth: ['9207']
    }),
    component: function component() {
      return import('@/pages/report/qmr/destroy');
    }
  }, {
    path: 'qmr/recall',
    name: "".concat(pre, "qmr-recall"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '产品召回记录',
      closable: true,
      auth: ['9208']
    }),
    component: function component() {
      return import('@/pages/report/qmr/recall');
    }
  }, {
    path: 'qmr/recall_accident',
    name: "".concat(pre, "qmr-recall_accident"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '医疗器械召回事故报告',
      closable: true,
      auth: ['9209']
    }),
    component: function component() {
      return import('@/pages/report/qmr/recall_accident');
    }
  }, {
    path: 'qmr/AEs',
    name: "".concat(pre, "qmr-AEs"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '随货同行单',
      closable: true,
      auth: ['9210']
    }),
    component: function component() {
      return import('@/pages/report/qmr/AEs');
    }
  }, {
    path: 'adm/account',
    name: "".concat(pre, "adm-account"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '员工人事档案',
      closable: true,
      auth: ['9301']
    }),
    component: function component() {
      return import('@/pages/report/adm/account');
    }
  }, {
    path: 'adm/training',
    name: "".concat(pre, "adm-training"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '职工培训档案',
      closable: true,
      auth: ['9302']
    }),
    component: function component() {
      return import('@/pages/report/adm/training');
    }
  }, {
    path: 'adm/healthy',
    name: "".concat(pre, "adm-healthy"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '员工健康档案',
      closable: true,
      auth: ['9303']
    }),
    component: function component() {
      return import('@/pages/report/adm/healthy');
    }
  }, {
    path: 'adm/device',
    name: "".concat(pre, "adm-device"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '设施设备档案',
      closable: true,
      auth: ['9304']
    }),
    component: function component() {
      return import('@/pages/report/adm/device');
    }
  }, {
    path: 'adm/commission',
    name: "".concat(pre, "adm-commission"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '销售人员法人委托书',
      closable: true,
      auth: ['9305']
    }),
    component: function component() {
      return import('@/pages/report/adm/commission');
    }
  }, {
    path: 'daily/degree',
    name: "".concat(pre, "daily-degree"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '湿温度记录',
      closable: true,
      auth: ['9401']
    }),
    component: function component() {
      return import('@/pages/report/daily/degree');
    }
  }, {
    path: 'daily/assess',
    name: "".concat(pre, "daily-assess"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '制度落实情况考核记录',
      closable: true,
      auth: ['9402']
    }),
    component: function component() {
      return import('@/pages/report/daily/assess');
    }
  }, {
    path: 'daily/repair',
    name: "".concat(pre, "daily-repair"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '设备使用检定维修记录',
      closable: true,
      auth: ['9403']
    }),
    component: function component() {
      return import('@/pages/report/daily/repair');
    }
  }, {
    path: 'daily/maintain',
    name: "".concat(pre, "daily-maintain"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '设备安装更换维护记录',
      closable: true,
      auth: ['9404']
    }),
    component: function component() {
      return import('@/pages/report/daily/maintain');
    }
  }, {
    path: 'daily/track',
    name: "".concat(pre, "daily-track"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '产品跟踪信息',
      closable: true,
      auth: ['9405']
    }),
    component: function component() {
      return import('@/pages/report/daily/track');
    }
  }, {
    path: 'declare/relationship',
    name: "".concat(pre, "declare-relationship"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '产品购销关系',
      closable: true,
      auth: ['9501']
    }),
    component: function component() {
      return import('@/pages/report/declare/relationship');
    }
  }]
};