import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "D:/Projects/medical-web/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'finance-';
export default {
  path: '/finance',
  name: 'finance',
  redirect: {
    name: "".concat(pre, "bill")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'bill',
    name: "".concat(pre, "bill"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      auth: ['601'],
      title: '对账',
      closable: false
    }),
    component: function component() {
      return import('@/pages/finance/bill');
    }
  }, {
    path: 'invoice',
    name: "".concat(pre, "invoice"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      auth: ['602'],
      title: '开票',
      closable: false
    }),
    component: function component() {
      return import('@/pages/finance/invoice');
    }
  }, {
    path: 'refund',
    name: "".concat(pre, "refund"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      auth: ['603'],
      title: '退票',
      closable: false
    }),
    component: function component() {
      return import('@/pages/finance/refund');
    }
  }, {
    path: 'web',
    name: "".concat(pre, "web"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      auth: ['604'],
      title: '财务系统连接',
      closable: false
    }),
    component: function component() {
      return import('@/pages/finance/web');
    }
  }]
};