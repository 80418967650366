import "core-js/modules/es6.regexp.split";
export default {
  methods: {
    tTitle: function tTitle(title) {
      if (title && title.indexOf('$t:') === 0) {
        return this.$t(title.split('$t:')[1]);
      } else {
        return title;
      }
    }
  }
};