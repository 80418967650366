import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "D:/Projects/medical-web/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'special-';
export default {
  path: '/special',
  name: 'special',
  redirect: {
    name: "".concat(pre, "lending")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'lending',
    name: "".concat(pre, "lending"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '借入/借出',
      closable: true,
      auth: ['501']
    }),
    component: function component() {
      return import('@/pages/special/lending');
    }
  }, {
    path: 'entrust',
    name: "".concat(pre, "entrust"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '委托指令',
      closable: true,
      auth: ['502']
    }),
    component: function component() {
      return import('@/pages/special/entrust');
    }
  }, {
    path: 'retreat',
    name: "".concat(pre, "retreat"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '委托撤回',
      closable: true,
      auth: ['503']
    }),
    component: function component() {
      return import('@/pages/special/retreat');
    }
  }]
};