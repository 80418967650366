var pre = '/system/';
export default {
  path: '/system',
  title: '系统设置',
  header: 'home',
  custom: 'i-icon-erp i-icon-erp-shezhi',
  children: [{
    icon: 'ios-contact',
    path: "".concat(pre, "account"),
    auth: ['801'],
    title: '用户管理'
  }, {
    custom: 'i-icon-erp i-icon-erp-bumen',
    path: "".concat(pre, "department"),
    auth: ['802'],
    title: '部门管理'
  }, {
    custom: 'i-icon-erp i-icon-erp-qiyefuwu',
    path: "".concat(pre, "companies"),
    auth: ['803'],
    title: '企业管理'
  }, {
    path: "".concat(pre, "tenant"),
    custom: 'i-icon-erp i-icon-erp-qiyefuwu',
    auth: ['807'],
    title: '企业信息'
  }, {
    custom: 'i-icon-erp i-icon-erp-xiaoshouyuan',
    path: "".concat(pre, "seller"),
    auth: ['804'],
    title: '销售员管理'
  }, {
    custom: 'i-icon-erp i-icon-erp-canshupeizhi',
    path: "".concat(pre, "site"),
    auth: ['805'],
    title: '参数设置'
  }, {
    custom: 'i-icon-erp i-icon-erp-jiaosequanxian',
    path: "".concat(pre, "role"),
    auth: ['806'],
    title: '角色设置'
  }, {
    custom: 'i-icon-erp i-icon-erp-danju',
    path: "".concat(pre, "initialize"),
    auth: ['808'],
    title: '系统初始化'
  }, {
    path: "".concat(pre, "user"),
    icon: 'ios-contact',
    title: '个人设置'
  }]
};