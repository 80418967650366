import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "D:/Projects/medical-web/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
import { on, off } from "view-design/src/utils/dom";
import { setMatchMedia } from "view-design/src/utils/assist";
import { mapMutations, mapState } from "vuex";

var VUE_APP_VERSION = require("../package.json");

setMatchMedia();
export default {
  name: "app",
  computed: _objectSpread({}, mapState("admin/user", ["info"])),
  methods: _objectSpread(_objectSpread({}, mapMutations("admin/layout", ["setDevice"])), {}, {
    handleWindowResize: function handleWindowResize() {
      this.handleMatchMedia();
    },
    handleMatchMedia: function handleMatchMedia() {
      var matchMedia = window.matchMedia;

      if (matchMedia("(max-width: 600px)").matches) {
        this.setDevice("Mobile");
      } else if (matchMedia("(max-width: 992px)").matches) {
        this.setDevice("Tablet");
      } else {
        this.setDevice("Desktop");
      }
    }
  }),
  created: function created() {
    var version = VUE_APP_VERSION.version; //版本号（每次上线前需要更新下版本号）

    var vers = window.localStorage.getItem("app_version");

    if (version != vers) {
      localStorage.clear();
      window.localStorage.setItem("app_version", version);
      location.reload();
    }
  },
  mounted: function mounted() {
    on(window, "resize", this.handleWindowResize);
    this.handleMatchMedia();
  },
  beforeDestroy: function beforeDestroy() {
    off(window, "resize", this.handleWindowResize);
  }
};