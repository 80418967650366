var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass:
        "i-layout-header-trigger i-layout-header-trigger-min i-layout-header-trigger-in"
    },
    [
      _c(
        "Notification",
        {
          staticClass: "i-layout-header-notice",
          class: { "i-layout-header-notice-mobile": _vm.isMobile },
          attrs: { wide: _vm.isMobile, "badge-props": _vm.badgeProps }
        },
        [
          _c("Icon", {
            attrs: { slot: "icon", custom: "i-icon i-icon-notification" },
            slot: "icon"
          }),
          _c("NotificationTab", { attrs: { title: "通知" } }),
          _c("NotificationTab", { attrs: { title: "消息" } }),
          _c("NotificationTab", { attrs: { title: "待办" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }