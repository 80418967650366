export default {
  locale: 'zh-CN',
  language: '简体中文',
  menu: {
    i18n: '多语言'
  },
  page: {
    login: {
      title: '登录',
      remember: '自动登录',
      forgot: '忘记密码',
      submit: '登录',
      other: '其它登录方式',
      signup: '注册账户'
    },
    exception: {
      e403: '抱歉，你无权访问该页面',
      e404: '抱歉，你访问的页面不存在',
      e500: '抱歉，服务器出错了',
      btn: '返回首页'
    },
    i18n: {
      content: '你好，很高兴认识你！'
    }
  }
};