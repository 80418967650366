import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "D:/Projects/medical-web/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/**
 * 布局配置
 * */
import screenfull from 'screenfull';
import Setting from '@/setting';
export default {
  namespaced: true,
  state: _objectSpread(_objectSpread({}, Setting.layout), {}, {
    isMobile: false,
    // 是否为手机
    isTablet: false,
    // 是否为平板
    isDesktop: true,
    // 是否为桌面
    isFullscreen: false // 是否切换到了全屏

  }),
  mutations: {
    /**
     * @description 设置设备类型
     * @param {Object} state vuex state
     * @param {String} type 设备类型，可选值为 Mobile、Tablet、Desktop
     */
    setDevice: function setDevice(state, type) {
      state.isMobile = false;
      state.isTablet = false;
      state.isDesktop = false;
      state["is".concat(type)] = true;
    },

    /**
     * @description 修改 menuCollapse
     * @param {Object} state vuex state
     * @param {Boolean} collapse 折叠状态
     * */
    updateMenuCollapse: function updateMenuCollapse(state, collapse) {
      state.menuCollapse = collapse;
    },

    /**
     * @description 设置全屏状态
     * @param {Object} state vuex state
     * @param {Boolean} isFullscreen vuex
     * */
    setFullscreen: function setFullscreen(state, isFullscreen) {
      state.isFullscreen = isFullscreen;
    },

    /**
     * @description 更改指定布局配置
     * @param {Object} state vuex state
     * @param {Object} key layout 名称，对应 Setting.layout
     * @param {Object} value layout 值
     * */
    updateLayoutSetting: function updateLayoutSetting(state, _ref) {
      var key = _ref.key,
          value = _ref.value;
      state[key] = value;
    }
  },
  actions: {
    /**
     * @description 初始化监听全屏状态
     */
    listenFullscreen: function listenFullscreen(_ref2) {
      var commit = _ref2.commit;
      return new Promise(function (resolve) {
        if (screenfull.enabled) {
          screenfull.on('change', function () {
            if (!screenfull.isFullscreen) {
              commit('setFullscreen', false);
            }
          });
        } // end


        resolve();
      });
    },

    /**
     * @description 切换全屏
     */
    toggleFullscreen: function toggleFullscreen(_ref3) {
      var commit = _ref3.commit;
      return new Promise(function (resolve) {
        if (screenfull.isFullscreen) {
          screenfull.exit();
          commit('setFullscreen', false);
        } else {
          screenfull.request();
          commit('setFullscreen', true);
        } // end


        resolve();
      });
    }
  }
};