var pre = '/report/';
export default {
  path: '/report',
  title: '报表',
  header: 'home',
  custom: 'i-icon-erp i-icon-erp-fl-baobiao',
  auth: ['9000', '9100', '9101', '9102', '9103', '9104', '9105', '9106', '9107', '9108', '9109', '9110', '9111',, '9112', '9200', '9201', '9202', '9203', '9204', '9205', '9206', '9207', '9208', '9209', '9210', '9300', '9301', '9302', '9303', '9304', '9305', '9400', '9401', '9402', '9403', '9404'],
  children: [{
    custom: 'i-icon-erp i-icon-erp-jingyingqingkuang-',
    path: "".concat(pre, "bpr"),
    title: '经营流程记录',
    auth: ['9100', '9101', '9102', '9103', '9104', '9105', '9106', '9107', '9108', '9109', '9110', '9111',, '9112'],
    children: [{
      custom: 'i-icon-erp i-icon-erp-chanpinguanli',
      path: "".concat(pre, "bpr/product"),
      auth: ['9101'],
      title: '首营产品档案'
    }, {
      custom: 'i-icon-erp i-icon-erp-gongsi',
      path: "".concat(pre, "bpr/company"),
      auth: ['9102'],
      title: '首营企业管理'
    }, {
      custom: 'i-icon-erp i-icon-erp-caigou',
      path: "".concat(pre, "bpr/purchase"),
      auth: ['9103'],
      title: '产品采购记录'
    }, {
      custom: 'i-icon-erp i-icon-erp-QAyanshou',
      path: "".concat(pre, "bpr/accrecord"),
      auth: ['9104'],
      title: '产品验收记录'
    }, {
      custom: 'i-icon-erp i-icon-erp-rukuguanli',
      path: "".concat(pre, "bpr/inwarehouse"),
      auth: ['9105'],
      title: '产品入库记录'
    }, {
      custom: 'i-icon-erp i-icon-erp-cangkukucun',
      path: "".concat(pre, "bpr/expired"),
      auth: ['9106'],
      title: '近效期产品催销表'
    }, {
      custom: 'i-icon-erp i-icon-erp-kucunpandian-',
      path: "".concat(pre, "bpr/check"),
      auth: ['9107'],
      title: '盘点记录'
    }, {
      custom: 'i-icon-erp i-icon-erp-chukuguanli',
      path: "".concat(pre, "bpr/outbound"),
      auth: ['9108'],
      title: '产品出库复核记录'
    }, {
      custom: 'i-icon-erp i-icon-erp-xiaoshoudingdan',
      path: "".concat(pre, "bpr/salerecord"),
      auth: ['9109'],
      title: '产品销售记录'
    }, {
      custom: 'i-icon-erp i-icon-erp-tuihuo',
      path: "".concat(pre, "bpr/returnrecord"),
      auth: ['9110'],
      title: '销后退回产品验收记录'
    }, {
      custom: 'i-icon-erp i-icon-erp-shouhou',
      path: "".concat(pre, "bpr/after"),
      auth: ['9111'],
      title: '售后服务记录'
    }, // {
    //     custom: 'i-icon-erp i-icon-erp-ss-ths',
    //     path: `${pre}bpr/record`,
    //     auth: ['200'],
    //     title: '产品运输记录'
    // },
    {
      custom: 'i-icon-erp i-icon-erp-danju',
      path: "".concat(pre, "bpr/peer"),
      auth: ['9112'],
      title: '随货同行单'
    }]
  }, {
    custom: 'i-icon-erp i-icon-erp-zhiliangguanlixin',
    path: "".concat(pre, "qmr"),
    title: '质量管理记录',
    auth: ['9200', '9201', '9202', '9203', '9204', '9205', '9206', '9207', '9208', '9209', '9210'],
    children: [{
      custom: 'i-icon-erp i-icon-erp-jiancha_yanhuo_checkup_examinegoods',
      path: "".concat(pre, "qmr/quality"),
      auth: ['9201'],
      title: '产品质量查询'
    }, {
      custom: 'i-icon-erp i-icon-erp-kehutousu',
      path: "".concat(pre, "qmr/complaint"),
      auth: ['9202'],
      title: '产品质量投诉处理记录'
    }, {
      custom: 'i-icon-erp i-icon-erp-shujuhuizong',
      path: "".concat(pre, "qmr/unqualified"),
      auth: ['9203'],
      title: '不合格产品处理汇总'
    }, {
      custom: 'i-icon-erp i-icon-erp-sunhuaijilu',
      path: "".concat(pre, "qmr/frmloss"),
      auth: ['9204'],
      title: '不合格产品确认及报损'
    }, {
      custom: 'i-icon-erp i-icon-erp-genzong',
      path: "".concat(pre, "qmr/track"),
      auth: ['9205'],
      title: '质量跟踪记录'
    }, {
      custom: 'i-icon-erp i-icon-erp-weizhangshigujilu',
      path: "".concat(pre, "qmr/accident"),
      auth: ['9206'],
      title: '质量事故报告记录'
    }, {
      custom: 'i-icon-erp i-icon-erp-a-2xiaohui',
      path: "".concat(pre, "qmr/destroy"),
      auth: ['9207'],
      title: '销毁记录'
    }, {
      custom: 'i-icon-erp i-icon-erp--_zhaohui',
      path: "".concat(pre, "qmr/recall"),
      auth: ['9208'],
      title: '产品召回记录'
    }, {
      custom: 'i-icon-erp i-icon-erp-zhaohuitongji',
      path: "".concat(pre, "qmr/recall_accident"),
      auth: ['9209'],
      title: '医疗器械召回事故报告'
    }, {
      custom: 'i-icon-erp i-icon-erp-cz-bljl',
      path: "".concat(pre, "qmr/AEs"),
      auth: ['9210'],
      title: '医疗器械不良事件'
    }]
  }, {
    custom: 'i-icon-erp i-icon-erp-hangzhengguanli',
    path: "".concat(pre, "adm"),
    title: '行政管理档案',
    auth: ['9300', '9301', '9302', '9303', '9304', '9305'],
    children: [{
      custom: 'i-icon-erp i-icon-erp-yuangongguanli',
      path: "".concat(pre, "adm/account"),
      auth: ['9301'],
      title: '员工人事档案'
    }, {
      custom: 'i-icon-erp i-icon-erp-peixun',
      path: "".concat(pre, "adm/training"),
      auth: ['9302'],
      title: '职工培训档案'
    }, {
      custom: 'i-icon-erp i-icon-erp-jiankang',
      path: "".concat(pre, "adm/healthy"),
      auth: ['9303'],
      title: '员工健康档案'
    }, {
      custom: 'i-icon-erp i-icon-erp-shebeiguanli',
      path: "".concat(pre, "adm/device"),
      auth: ['9304'],
      title: '设施设备档案'
    }, {
      custom: 'i-icon-erp i-icon-erp-shouquanweituoshu',
      path: "".concat(pre, "adm/commission"),
      auth: ['9305'],
      title: '销售人员法人委托书'
    }]
  }, {
    custom: 'i-icon-erp i-icon-erp-richangxuncha',
    path: "".concat(pre, "daily"),
    title: '日常管理记录',
    auth: ['9400', '9401', '9402', '9403', '9404'],
    children: [{
      custom: 'i-icon-erp i-icon-erp-ss-ths',
      path: "".concat(pre, "daily/degree"),
      auth: ['9401'],
      title: '湿温度记录'
    }, {
      custom: 'i-icon-erp i-icon-erp-yuedukaohe',
      path: "".concat(pre, "daily/assess"),
      auth: ['9402'],
      title: '制度落实情况考核记录'
    }, {
      custom: 'i-icon-erp i-icon-erp-weixiu',
      path: "".concat(pre, "daily/repair"),
      auth: ['9403'],
      title: '设备使用检定维修'
    }, {
      custom: 'i-icon-erp i-icon-erp-xitongweihu-',
      path: "".concat(pre, "daily/maintain"),
      auth: ['9404'],
      title: '设备安装更换维护记录'
    }, {
      icon: 'ios-locate-outline',
      path: "".concat(pre, "daily/track"),
      auth: ['9405'],
      title: '产品跟踪信息'
    }]
  }, {
    custom: 'i-icon-erp i-icon-erp-xiangmushenbao',
    path: "".concat(pre, "declare"),
    title: '追述申报',
    auth: ['9500', '9501'],
    children: [{
      custom: 'i-icon-erp i-icon-erp-guanxitu',
      path: "".concat(pre, "declare/relationship"),
      auth: ['9501'],
      title: '产品购销关系'
    }]
  }]
};