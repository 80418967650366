var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      on: {
        mouseenter: function($event) {
          return _vm.showImg("open")
        },
        mouseleave: function($event) {
          return _vm.showImg("close")
        }
      }
    },
    [
      _c(
        "Tooltip",
        {
          attrs: {
            content: "点击/手机浏览器扫码下载仓库APP",
            placement: "left",
            transfer: ""
          }
        },
        [
          _c("Icon", {
            attrs: { type: "md-cloud-download" },
            on: { click: _vm.downLoad }
          })
        ],
        1
      ),
      _vm.showImgType
        ? _c("span", { staticClass: "imgBox" }, [
            _c("img", {
              staticClass: "imgSize",
              attrs: {
                src: require("@/assets/images/smq.png"),
                alt: "加载中。。。"
              }
            })
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }