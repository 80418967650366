//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'iHeaderDownload',
  data: function data() {
    return {
      showImgType: false
    };
  },
  methods: {
    showImg: function showImg(type) {
      var vm = this;

      if (type == "open") {
        vm.showImgType = true; // setTimeout(function () {
        // }, 100)
      } else {
        vm.showImgType = false; // setTimeout(function () {
        // }, 300)
      }
    },
    downLoad: function downLoad() {
      // console.log('下载安装包')
      var a = document.createElement('a');
      a.href = "https://roadgine-app.oss-cn-zhangjiakou.aliyuncs.com/MediStore.apk";
      a.click();
    }
  }
};