import request from '@/plugins/request';
export function AccountLogin(data) {
  return request({
    url: '/api/login',
    method: 'post',
    data: data
  });
}
export function signout() {
  return request({
    url: '/api/login/signout',
    method: 'post'
  });
}