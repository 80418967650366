import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "D:/Projects/medical-web/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'dashboard-';
export default {
  path: '/dashboard',
  name: 'dashboard',
  redirect: {
    name: "".concat(pre, "console")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'business',
    name: "".concat(pre, "business"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '待审核业务',
      closable: false,
      auth: ['111']
    }),
    component: function component() {
      return import('@/pages/dashboard/business');
    }
  }, {
    path: 'pending',
    name: "".concat(pre, "pending"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '待处理业务',
      closable: false,
      auth: ['113']
    }),
    component: function component() {
      return import('@/pages/dashboard/pending');
    }
  }, {
    path: 'management',
    name: "".concat(pre, "management"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '首营待审核',
      closable: false,
      auth: ['112']
    }),
    component: function component() {
      return import('@/pages/dashboard/management');
    }
  }, {
    path: 'warn',
    name: "".concat(pre, "warn"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '库存预警',
      closable: false,
      auth: ['114']
    }),
    component: function component() {
      return import('@/pages/dashboard/warn');
    }
  }, {
    path: 'console',
    name: "".concat(pre, "console"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '时效提醒',
      closable: false
    }),
    component: function component() {
      return import('@/pages/dashboard/console');
    }
  }]
};