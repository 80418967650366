var pre = '/shopping/';
export default {
  path: '/shopping',
  title: '采购管理',
  header: 'home',
  custom: 'i-icon-erp i-icon-erp-navicon-cggl',
  auth: ['200', '201', '203'],
  children: [{
    custom: 'i-icon-erp i-icon-erp-caigou',
    path: "".concat(pre, "procured"),
    auth: ['201'],
    title: '采购单'
  }, {
    custom: 'i-icon-erp i-icon-erp-shouhou',
    path: "".concat(pre, "return"),
    auth: ['203'],
    title: '退货'
  }]
};