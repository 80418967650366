import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "D:/Projects/medical-web/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'manage-';
export default {
  path: '/manage',
  name: 'manage',
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'company',
    name: "".concat(pre, "company"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '企业管理',
      closable: true,
      cache: false,
      auth: ['702']
    }),
    component: function component() {
      return import('@/pages/manage/company');
    }
  }, {
    path: 'company/create',
    name: "".concat(pre, "company-create"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '新建机构',
      closable: true,
      cache: false,
      auth: ['702'],
      tabs: false
    }),
    component: function component() {
      return import('@/pages/manage/company/create');
    }
  }, {
    path: 'company/edit',
    name: "".concat(pre, "company-edit"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '修改机构信息',
      closable: true,
      cache: false,
      auth: ['702'],
      tabs: false
    }),
    component: function component() {
      return import('@/pages/manage/company/edit');
    }
  }, {
    path: 'company/detail',
    name: "".concat(pre, "company-detail"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '机构详情',
      closable: true,
      cache: false,
      auth: ['702'],
      tabs: false
    }),
    component: function component() {
      return import('@/pages/manage/company/detail');
    }
  }, {
    path: 'product',
    name: "".concat(pre, "product"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '产品管理',
      auth: ['701'],
      closable: true,
      cache: false
    }),
    component: function component() {
      return import('@/pages/manage/product');
    }
  }, {
    path: 'product/create',
    name: "".concat(pre, "product-create"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '新建产品',
      closable: true,
      cache: false,
      auth: ['701'],
      tabs: false
    }),
    component: function component() {
      return import('@/pages/manage/product/create');
    }
  }, {
    path: 'product/edit',
    name: "".concat(pre, "product-edit"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '修改产品信息',
      closable: true,
      cache: false,
      auth: ['701'],
      tabs: false
    }),
    component: function component() {
      return import('@/pages/manage/product/edit');
    }
  }, {
    path: 'product/detail',
    name: "".concat(pre, "product-detail"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '产品详情',
      closable: true,
      cache: false,
      auth: ['701'],
      tabs: false
    }),
    component: function component() {
      return import('@/pages/manage/product/detail');
    }
  }]
};