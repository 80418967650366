var pre = '/finance/';
export default {
  path: '/finance',
  title: '财务管理',
  header: 'home',
  auth: ['601', '602', '603', '604'],
  custom: 'i-icon-erp i-icon-erp-caiwu',
  children: [{
    custom: 'i-icon-erp i-icon-erp-duizhangdanbill',
    path: "".concat(pre, "bill"),
    auth: ['601'],
    title: '对账'
  }, {
    custom: 'i-icon-erp i-icon-erp-kaipiao',
    path: "".concat(pre, "invoice"),
    auth: ['602'],
    title: '开票'
  }, {
    custom: 'i-icon-erp i-icon-erp-tuipiao',
    path: "".concat(pre, "refund"),
    auth: ['603'],
    title: '退票'
  }, {
    custom: 'i-icon-erp i-icon-erp-jingyingqingkuang-',
    path: "".concat(pre, "web"),
    auth: ['604'],
    title: '财务系统连接'
  }]
};