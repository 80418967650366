import req from '@/plugins/request';
export function GetTenants(data) {
  return req({
    url: '/api/Tenants?' + data.params,
    method: 'get',
    data: data
  });
}
export function getTenant(data) {
  return req({
    url: '/api/Tenants/' + data.id,
    method: 'get',
    data: data
  });
}
export function GetTenantBizs(data) {
  return req({
    url: '/api/Tenants/getTenantBizs/' + data.id,
    method: 'get',
    data: data
  });
}
export function OcrBizLicense(data) {
  return req({
    url: '/api/Tenants/ocrBizLicense?filePath=' + data.filePath,
    method: 'post',
    data: data
  });
}
export function CreateTenant(data) {
  return req({
    url: '/api/Tenants',
    method: 'post',
    data: data
  });
}
export function UpdateTenant(data) {
  return req({
    url: '/api/Tenants/' + data.id,
    method: 'PUT',
    data: data
  });
}
export function DeleteTenant(data) {
  return req({
    url: '/api/Tenants/' + data.id,
    method: 'DELETE',
    data: data
  });
}
export function ChangeTenantStatus(data) {
  return req({
    url: '/api/Tenants/changeStatus',
    method: 'POST',
    data: data
  });
}
export function GetBizScopes(data) {
  return req({
    url: '/api/BizScopes',
    method: 'get',
    data: data
  });
}
export function GetBranches(data) {
  return req({
    url: '/api/Branches?' + data.params,
    method: 'get',
    data: data
  });
}
export function GetBranchList(data) {
  return req({
    url: '/api/Branches/GetBranchList',
    method: 'get',
    data: data
  });
}
export function UpdateBranch(data) {
  return req({
    url: '/api/Branches/' + data.id,
    method: 'post',
    data: data
  });
}
export function CreateBranch(data) {
  return req({
    url: '/api/Branches',
    method: 'post',
    data: data
  });
}
export function DeleteBranch(data) {
  return req({
    url: '/api/Branches/' + data.id,
    method: 'DELETE',
    data: data
  });
}