import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.split";
import cookies from "./util.cookies";
import log from "./util.log";
import db from "./util.db";
import Setting from '@/setting';
var util = {
  cookies: cookies,
  log: log,
  db: db
};

function tTitle() {
  var title = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

  if (window && window.$t) {
    if (title.indexOf('$t:') === 0) {
      return window.$t(title.split('$t:')[1]);
    } else {
      return title;
    }
  } else {
    return title;
  }
}
/**
 * @description 更改标题
 * @param {Object} title 标题
 * @param {Object} count 未读消息数提示（可视情况选择使用或不使用）
 */


util.title = function (_ref) {
  var title = _ref.title,
      count = _ref.count;
  title = tTitle(title);
  var fullTitle = title ? "".concat(title, " - ").concat(Setting.titleSuffix) : Setting.titleSuffix;
  if (count) fullTitle = "(".concat(count, "\u6761\u6D88\u606F)").concat(fullTitle);
  window.document.title = fullTitle;
};

function requestAnimation(task) {
  if ('requestAnimationFrame' in window) {
    return window.requestAnimationFrame(task);
  }

  setTimeout(task, 16);
}

function getdate() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();

  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }

  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }

  var currentdate = year + "-" + month + "-" + strDate;
  return currentdate;
}

function getdateAndTime() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  var hour = date.getHours();
  var minutes = date.getMinutes();

  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }

  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }

  if (hour >= 0 && hour <= 9) {
    hour = "0" + hour;
  }

  if (minutes >= 0 && minutes <= 9) {
    minutes = "0" + minutes;
  }

  var currentdate = year + "-" + month + "-" + strDate + " " + hour + ":" + minutes;
  return currentdate;
}

function formatDate(date, n) {
  var seperator = "-";
  var newDate = new Date(date.getTime() - n * 24 * 60 * 60 * 1000);
  var year = newDate.getFullYear();
  var month = newDate.getMonth() + 1;

  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }

  var day = newDate.getDate();

  if (day >= 0 && day <= 9) {
    day = "0" + day;
  }

  return year.toString() + seperator + month.toString() + seperator + day.toString();
}

export { requestAnimation, getdate, getdateAndTime, formatDate };
export default util;