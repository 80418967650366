//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "i-copyright",
  data: function data() {
    return {
      copyright: "Copyright © 2023 上海康银医疗器械有限公司 | 沪ICP备2022034851号  |"
    };
  },
  methods: {
    trunTag: function trunTag() {
      window.open("https://beian.miit.gov.cn/", "_blank");
    }
  }
};