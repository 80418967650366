var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer" }, [
    _c(
      "div",
      { staticClass: "footer-a" },
      [
        _c("GlobalFooter", {
          staticClass: "i-copyright",
          staticStyle: { padding: "0px" },
          attrs: { copyright: _vm.copyright },
          nativeOn: {
            click: function($event) {
              return _vm.trunTag.apply(null, arguments)
            }
          }
        })
      ],
      1
    ),
    _vm._v("\n   \n  "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-b" }, [
      _c(
        "a",
        {
          staticStyle: {
            display: "inline-block",
            "text-decoration": "none",
            height: "20px",
            "line-height": "20px"
          },
          attrs: {
            target: "_blank",
            href:
              "https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402009945"
          }
        },
        [
          _c("img", {
            staticStyle: { float: "left" },
            attrs: { src: require("@/assets/images/plic.png") }
          }),
          _c(
            "p",
            {
              staticStyle: {
                float: "left",
                height: "20px",
                "line-height": "20px",
                margin: "0px 0px 0px 5px",
                color: "#808695"
              }
            },
            [_vm._v("\n        沪公网安备 31010402009945号\n      ")]
          )
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }