import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "D:/Projects/medical-web/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
export default {
  name: 'iHeaderLog',
  computed: _objectSpread(_objectSpread({}, mapGetters('admin/log', ['length', 'lengthError'])), {}, {
    showDot: function showDot() {
      return !!this.length && this.lengthError === 0;
    },
    tooltipContent: function tooltipContent() {
      if (!this.length) {
        return '没有日志或异常';
      } else {
        var text = "".concat(this.length, " \u6761\u65E5\u5FD7");
        if (this.lengthError) text += " | \u5305\u542B ".concat(this.lengthError, " \u4E2A\u5F02\u5E38");
        return text;
      }
    }
  }),
  methods: {
    handleOpenLog: function handleOpenLog() {
      this.$router.push({
        name: 'log'
      }, function () {});
    }
  }
};