var pre = '/sale/';
export default {
  path: '/sale',
  title: '销售管理',
  header: 'home',
  custom: 'i-icon-erp i-icon-erp-xiaoshouguanli1',
  auth: ['401', '402', '403', '404', '405', '407'],
  children: [{
    custom: 'i-icon-erp i-icon-erp-jiageguanxiguanli',
    path: "".concat(pre, "relation"),
    auth: ['401'],
    title: '关系设定',
    children: [{
      path: "".concat(pre, "relation/create"),
      title: '关系设定新建',
      auth: ['hidden']
    }, {
      path: "".concat(pre, "relation/edit"),
      title: '关系设定编辑',
      auth: ['hidden']
    }, {
      path: "".concat(pre, "relation/detail"),
      title: '关系设定详情',
      auth: ['hidden']
    }]
  }, {
    custom: 'i-icon-erp i-icon-erp-xiaoshoudingdan',
    path: "".concat(pre, "order"),
    auth: ['402'],
    title: '销售订单'
  }, {
    custom: 'i-icon-erp i-icon-erp-wodeweituo',
    path: "".concat(pre, "dispatch"),
    auth: ['403'],
    title: '委托单'
  }, {
    custom: 'i-icon-erp i-icon-erp-shouhou',
    path: "".concat(pre, "afterSale"),
    auth: ['404'],
    title: '退换货'
  }, {
    custom: 'i-icon-erp i-icon-erp-kehutousu',
    path: "".concat(pre, "complaint"),
    title: '投诉',
    auth: ['405']
  }, {
    custom: 'i-icon-erp i-icon-erp-kehutousu',
    path: "".concat(pre, "service"),
    title: '质量跟踪',
    auth: ['407']
  } // {
  //     custom: 'i-icon-erp i-icon-erp-danju',
  //     path: `${pre}peer`, 
  //     title: '随货同行单',
  //     auth: ['406'],
  // }
  ]
};