var pre = '/storage/';
export default {
  path: '/storage',
  title: '仓储管理',
  header: 'home',
  custom: 'i-icon-erp i-icon-erp-cangchu-',
  auth: ['302', '303', '304', '305', '306', '307', '308', '309', '320', '321', '322', '323', '202'],
  children: [{
    custom: 'i-icon-erp i-icon-erp-QAyanshou',
    path: "/shopping/acceptance",
    auth: ['202'],
    title: '验收'
  }, {
    custom: 'i-icon-erp i-icon-erp-rukuguanli',
    path: "".concat(pre, "in"),
    auth: ['302'],
    title: '入库'
  }, {
    custom: 'i-icon-erp i-icon-erp-cangkukucun',
    path: "".concat(pre, "stock"),
    auth: ['303'],
    title: '当前库存'
  }, {
    custom: 'i-icon-erp i-icon-erp-chukuguanli',
    path: "".concat(pre, "ex"),
    auth: ['304'],
    title: '出库'
  }, {
    custom: 'i-icon-erp i-icon-erp-kucunpandian-',
    path: "".concat(pre, "check"),
    auth: ['305'],
    title: '盘点'
  }, {
    custom: 'i-icon-erp i-icon-erp-sunhuaijilu',
    path: "".concat(pre, "damaged"),
    auth: ['306'],
    title: '报损'
  }, {
    custom: 'i-icon-erp i-icon-erp-yiku',
    path: "".concat(pre, "move"),
    auth: ['307'],
    title: '移库'
  }, {
    custom: 'i-icon-erp i-icon-erp-a-2xiaohui',
    path: "".concat(pre, "destruction"),
    auth: ['309'],
    title: '销毁'
  }, {
    custom: 'i-icon-erp i-icon-erp-danju',
    path: "/sale/peer",
    title: '随货同行单',
    auth: ['406']
  }, {
    custom: 'i-icon-erp i-icon-erp-ss-ths',
    path: "".concat(pre, "setup/record"),
    auth: ['323'],
    title: '湿温度记录'
  }, {
    custom: 'i-icon-erp i-icon-erp-cangku_cangchuguanli_o',
    path: "".concat(pre, "setup"),
    title: '仓库设置',
    auth: ['308', '320', '321', '322'],
    children: [{
      custom: 'i-icon-erp i-icon-erp-39cangkuguanli',
      path: "".concat(pre, "setup/warehouse"),
      auth: ['320'],
      title: '仓库信息'
    }, {
      custom: 'i-icon-erp i-icon-erp-leixing',
      path: "".concat(pre, "setup/loctype"),
      auth: ['321'],
      title: '库位类型'
    }, {
      custom: 'i-icon-erp i-icon-erp-kuweiguanli',
      path: "".concat(pre, "setup/location"),
      auth: ['322'],
      title: '库位'
    }]
  }]
};