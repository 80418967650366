import "core-js/modules/es6.array.sort";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/web.dom.iterable";
import dayjs from 'dayjs'; //字符串截取前10位

export function Intercept(data) {
  return data.substring(0, 10);
} //格式化始日期

export function FormatDay(data) {
  return dayjs(data).format("YYYY-MM-DD");
} //格式化当前日期

export function FormatCurrentDate() {
  return dayjs().format("YYYY-MM-DD");
} //格式化当前日期时间

export function FormatCurrentDateTime() {
  return dayjs().format("YYYY-MM-DD HH:mm");
} //格式化日期时间

export function FormatDateTime(date) {
  return dayjs(date).format("YYYY-MM-DD HH:mm");
}
export function CurrentDateRange(data) {
  var date = new Date();
  date.setDate(date.getDate() - 7);
  var currentDate = [];
  currentDate.push(date);
  currentDate.push(new Date());
  data = currentDate;
  return data;
} //格式经营范围

export function treeBizScope(data) {
  var list2tree4 = function list2tree4(data, parentId) {
    var menuObj = {};
    data.forEach(function (item) {
      item.children = [];
      item.value = item.id.toString();
      menuObj[item.id] = item;
    });
    return data.filter(function (item) {
      if (item.parentId !== parentId) {
        if (item.parentName != null) {
          var index = item.parentName.indexOf("类");
          item.name = item.parentName.substring(0, index + 1) + "-" + item.name;
        }

        menuObj[item.parentId].children.push(item);
        return false;
      }

      return true;
    });
  };

  list2tree4(data, null);
  data.forEach(function (item) {
    if (item.parentName === null) {
      var children = {
        children: [],
        id: item.id,
        name: item.name,
        number: null,
        parentId: item.id,
        parentName: item.name,
        value: item.id.toString()
      };
      item.children.push(children);
      item.children = item.children.sort(function (a, b) {
        return a.id - b.id;
      });
    }
  });
  return data;
}