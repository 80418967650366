import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "D:/Projects/medical-web/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'sale-';
export default {
  path: '/sale',
  name: 'sale',
  redirect: {
    name: "".concat(pre, "relation")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'relation',
    name: "".concat(pre, "relation"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '关系设定',
      closable: true,
      scollTopPosition: 0,
      cache: false,
      auth: ['401']
    }),
    component: function component() {
      return import('@/pages/sale/relation');
    }
  }, {
    path: 'relation/create',
    name: "".concat(pre, "relation-create"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '关系设定新建',
      closable: true,
      cache: false,
      auth: ['401'] // tabs:false

    }),
    component: function component() {
      return import('@/pages/sale/relation/create');
    }
  }, {
    path: 'relation/edit',
    name: "".concat(pre, "relation-edit"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '关系设定编辑',
      closable: true,
      cache: false,
      auth: ['401'] // tabs:false

    }),
    component: function component() {
      return import('@/pages/sale/relation/edit');
    }
  }, {
    path: 'relation/detail',
    name: "".concat(pre, "relation-detail"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '关系设定详情',
      closable: true,
      auth: ['401'] // cache: false,

    }),
    component: function component() {
      return import('@/pages/sale/relation/detail');
    }
  }, {
    path: 'order',
    name: "".concat(pre, "order"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '销售订单',
      closable: true,
      scollTopPosition: 0,
      cache: false,
      auth: ['402']
    }),
    component: function component() {
      return import('@/pages/sale/order');
    }
  }, {
    path: 'dispatch',
    name: "".concat(pre, "dispatch"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '委托单',
      closable: true,
      scollTopPosition: 0,
      cache: false,
      auth: ['403']
    }),
    component: function component() {
      return import('@/pages/sale/dispatch');
    }
  }, {
    path: 'afterSale',
    name: "".concat(pre, "afterSale"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '退换货',
      closable: true,
      scollTopPosition: 0,
      cache: false,
      auth: ['404']
    }),
    component: function component() {
      return import('@/pages/sale/afterSale');
    }
  }, {
    path: 'complaint',
    name: "".concat(pre, "complaint"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '投诉',
      closable: true,
      scollTopPosition: 0,
      cache: false,
      auth: ['405']
    }),
    component: function component() {
      return import('@/pages/sale/complaint');
    }
  }, {
    path: 'service',
    name: "".concat(pre, "service"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '质量跟踪',
      closable: true,
      scollTopPosition: 0,
      cache: false,
      auth: ['405']
    }),
    component: function component() {
      return import('@/pages/sale/service');
    }
  }, {
    path: 'peer',
    name: "".concat(pre, "peer"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '随货同行单',
      closable: true,
      scollTopPosition: 0,
      cache: false,
      auth: ['406']
    }),
    component: function component() {
      return import('@/pages/sale/peer');
    }
  }]
};