import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "D:/Projects/medical-web/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'system-';
export default {
  path: '/system',
  name: 'system',
  redirect: {
    name: "".concat(pre, "companies")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'companies',
    name: "".concat(pre, "companies"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '企业管理',
      closable: true,
      cache: false,
      auth: ['803']
    }),
    component: function component() {
      return import('@/pages/system/companies');
    }
  }, {
    path: 'account',
    name: "".concat(pre, "account"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '用户设置',
      closable: true,
      auth: ['801']
    }),
    component: function component() {
      return import('@/pages/system/account');
    }
  }, {
    path: 'department',
    name: "".concat(pre, "department"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '部门设置',
      closable: true,
      auth: ['802']
    }),
    component: function component() {
      return import('@/pages/system/department');
    }
  }, {
    path: 'seller',
    name: "".concat(pre, "seller"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '销售员',
      closable: true,
      auth: ['804']
    }),
    component: function component() {
      return import('@/pages/system/seller');
    }
  }, {
    path: 'site',
    name: "".concat(pre, "site"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '参数设置',
      closable: true,
      auth: ['805']
    }),
    component: function component() {
      return import('@/pages/system/site');
    }
  }, {
    path: 'role',
    name: "".concat(pre, "role"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '角色设置',
      closable: true,
      auth: ['806']
    }),
    component: function component() {
      return import('@/pages/system/role');
    }
  }, {
    path: 'initialize',
    name: "".concat(pre, "initialize"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '系统初始化',
      closable: true,
      auth: ['808']
    }),
    component: function component() {
      return import('@/pages/system/initialize');
    }
  }, {
    path: 'tenant',
    name: "".concat(pre, "tenant"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '企业信息',
      closable: true,
      auth: ['807']
    }),
    component: function component() {
      return import('@/pages/system/tenant');
    }
  }, {
    path: 'user',
    name: "".concat(pre, "user"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '个人设置',
      closable: true
    }),
    component: function component() {
      return import('@/pages/system/user');
    }
  }]
};