var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.tenantInfo && _vm.formReady
        ? _c(
            "Form",
            {
              ref: "userInfoForm",
              attrs: {
                model: _vm.tenantInfo,
                rules: _vm.tenantInfoRules,
                "label-position": "top",
                "required-mark": ""
              }
            },
            [
              _c(
                "Card",
                {
                  attrs: { bordered: false, "dis-hover": "", title: "基本信息" }
                },
                [
                  _c(
                    "Row",
                    { attrs: { gutter: 64 } },
                    [
                      _c(
                        "Col",
                        { attrs: { span: "12" } },
                        [
                          _c(
                            "FormItem",
                            { attrs: { prop: "name", label: "企业名称" } },
                            [
                              _c("Input", {
                                attrs: { "element-id": "info_name" },
                                model: {
                                  value: _vm.tenantInfo.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.tenantInfo, "name", $$v)
                                  },
                                  expression: "tenantInfo.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: "12" } },
                        [
                          _c(
                            "FormItem",
                            { attrs: { prop: "city", label: "注册城市" } },
                            [
                              _c("City", {
                                attrs: {
                                  "show-suffix": "",
                                  "use-name": "",
                                  "element-id": "info_city"
                                },
                                on: { "on-change": _vm.handleSelectCity },
                                model: {
                                  value: _vm.tenantInfo.city,
                                  callback: function($$v) {
                                    _vm.$set(_vm.tenantInfo, "city", $$v)
                                  },
                                  expression: "tenantInfo.city"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: "12" } },
                        [
                          _c(
                            "FormItem",
                            {
                              attrs: {
                                prop: "registerAddress",
                                label: "注册地址"
                              }
                            },
                            [
                              _c("Input", {
                                attrs: { "element-id": "info_registerAddress" },
                                model: {
                                  value: _vm.tenantInfo.registerAddress,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.tenantInfo,
                                      "registerAddress",
                                      $$v
                                    )
                                  },
                                  expression: "tenantInfo.registerAddress"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: "12" } },
                        [
                          _c(
                            "FormItem",
                            {
                              attrs: {
                                prop: "taxNumber",
                                label: "社会信用代码"
                              }
                            },
                            [
                              _c("Input", {
                                attrs: { "element-id": "info_taxNumber" },
                                model: {
                                  value: _vm.tenantInfo.taxNumber,
                                  callback: function($$v) {
                                    _vm.$set(_vm.tenantInfo, "taxNumber", $$v)
                                  },
                                  expression: "tenantInfo.taxNumber"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: "12" } },
                        [
                          _c(
                            "FormItem",
                            {
                              attrs: {
                                prop: "bizLicenseValidTo",
                                label: "有效期"
                              }
                            },
                            [
                              _c("DatePicker", {
                                directives: [
                                  {
                                    name: "width",
                                    rawName: "v-width",
                                    value: "50%",
                                    expression: "'50%'"
                                  }
                                ],
                                attrs: {
                                  type: "date",
                                  editable: false,
                                  "element-id": "info_bizLicenseValidTo"
                                },
                                model: {
                                  value: _vm.tenantInfo.bizLicenseValidTo,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.tenantInfo,
                                      "bizLicenseValidTo",
                                      $$v
                                    )
                                  },
                                  expression: "tenantInfo.bizLicenseValidTo"
                                }
                              }),
                              _c(
                                "Checkbox",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { "element-id": "info_isPermanent" },
                                  model: {
                                    value: _vm.tenantInfo.isPermanent,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.tenantInfo,
                                        "isPermanent",
                                        $$v
                                      )
                                    },
                                    expression: "tenantInfo.isPermanent"
                                  }
                                },
                                [_vm._v("永久有效")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: "12" } },
                        [
                          _c(
                            "FormItem",
                            { attrs: { prop: "legalPerson", label: "法人" } },
                            [
                              _c("Input", {
                                attrs: { "element-id": "info_legalPerson" },
                                model: {
                                  value: _vm.tenantInfo.legalPerson,
                                  callback: function($$v) {
                                    _vm.$set(_vm.tenantInfo, "legalPerson", $$v)
                                  },
                                  expression: "tenantInfo.legalPerson"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: "12" } },
                        [
                          _c(
                            "FormItem",
                            {
                              attrs: { prop: "fundAccount", label: "开户信息" }
                            },
                            [
                              _c("Input", {
                                attrs: { "element-id": "info_fundAccount" },
                                model: {
                                  value: _vm.tenantInfo.fundAccount,
                                  callback: function($$v) {
                                    _vm.$set(_vm.tenantInfo, "fundAccount", $$v)
                                  },
                                  expression: "tenantInfo.fundAccount"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: "12" } },
                        [
                          _c(
                            "FormItem",
                            { attrs: { prop: "bizModel", label: "经营模式" } },
                            [
                              _c(
                                "Select",
                                {
                                  attrs: { "element-id": "info_bizModel" },
                                  model: {
                                    value: _vm.tenantInfo.bizModel,
                                    callback: function($$v) {
                                      _vm.$set(_vm.tenantInfo, "bizModel", $$v)
                                    },
                                    expression: "tenantInfo.bizModel"
                                  }
                                },
                                [
                                  _c("Option", { attrs: { value: "零售" } }, [
                                    _vm._v("零售")
                                  ]),
                                  _c("Option", { attrs: { value: "批发" } }, [
                                    _vm._v("批发")
                                  ]),
                                  _c(
                                    "Option",
                                    { attrs: { value: "零售和批发" } },
                                    [_vm._v("零售和批发")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: "12" } },
                        [
                          _c(
                            "FormItem",
                            { attrs: { label: "经营范围", prop: "bizScopes" } },
                            [
                              _c(
                                "Select",
                                {
                                  attrs: {
                                    "default-label":
                                      _vm.tenantInfo.defaultbizScopes,
                                    multiple: "",
                                    filterable: "",
                                    "element-id": "info_bizScopes"
                                  },
                                  on: { "on-change": _vm.handleSelectBizScope },
                                  model: {
                                    value: _vm.tenantInfo.bizScopes,
                                    callback: function($$v) {
                                      _vm.$set(_vm.tenantInfo, "bizScopes", $$v)
                                    },
                                    expression: "tenantInfo.bizScopes"
                                  }
                                },
                                _vm._l(_vm.BizScopes, function(group, index) {
                                  return _c(
                                    "OptionGroup",
                                    {
                                      key: index,
                                      attrs: { label: group.name }
                                    },
                                    _vm._l(group.children, function(item, idx) {
                                      return _c("Option", {
                                        key: idx,
                                        attrs: {
                                          value: item.value,
                                          label: item.name
                                        }
                                      })
                                    }),
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: "12" } },
                        [
                          _c(
                            "FormItem",
                            { attrs: { label: "是否经营体外诊断试剂" } },
                            [
                              _c("i-switch", {
                                attrs: { "element-id": "info_canSaleReagents" },
                                model: {
                                  value: _vm.tenantInfo.canSaleReagents,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.tenantInfo,
                                      "canSaleReagents",
                                      $$v
                                    )
                                  },
                                  expression: "tenantInfo.canSaleReagents"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: "12" } },
                        [
                          _c(
                            "FormItem",
                            { attrs: { prop: "officeAddress", label: "住所" } },
                            [
                              _c("Input", {
                                attrs: { "element-id": "info_officeAddress" },
                                model: {
                                  value: _vm.tenantInfo.officeAddress,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.tenantInfo,
                                      "officeAddress",
                                      $$v
                                    )
                                  },
                                  expression: "tenantInfo.officeAddress"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: "12" } },
                        [
                          _c(
                            "FormItem",
                            {
                              attrs: {
                                prop: "contactName",
                                label: "联系人信息"
                              }
                            },
                            [
                              _c("Input", {
                                attrs: { "element-id": "info_contactName" },
                                model: {
                                  value: _vm.tenantInfo.contactName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.tenantInfo, "contactName", $$v)
                                  },
                                  expression: "tenantInfo.contactName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: "12" } },
                        [
                          _c(
                            "FormItem",
                            {
                              attrs: { prop: "qaLeader", label: "质量负责人" }
                            },
                            [
                              _c("Input", {
                                attrs: { "element-id": "info_qaLeader" },
                                model: {
                                  value: _vm.tenantInfo.qaLeader,
                                  callback: function($$v) {
                                    _vm.$set(_vm.tenantInfo, "qaLeader", $$v)
                                  },
                                  expression: "tenantInfo.qaLeader"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: "12" } },
                        [
                          _c(
                            "FormItem",
                            {
                              attrs: { prop: "bizLeader", label: "企业负责人" }
                            },
                            [
                              _c("Input", {
                                attrs: { "element-id": "info_bizLeader" },
                                model: {
                                  value: _vm.tenantInfo.bizLeader,
                                  callback: function($$v) {
                                    _vm.$set(_vm.tenantInfo, "bizLeader", $$v)
                                  },
                                  expression: "tenantInfo.bizLeader"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: "12" } },
                        [
                          _c(
                            "FormItem",
                            {
                              attrs: {
                                prop: "scopeDescription",
                                label: "经营范围详情"
                              }
                            },
                            [
                              _c("Input", {
                                attrs: {
                                  "element-id": "info_scopeDescription",
                                  type: "textarea",
                                  autosize: { minRows: 4 }
                                },
                                model: {
                                  value: _vm.tenantInfo.scopeDescription,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.tenantInfo,
                                      "scopeDescription",
                                      $$v
                                    )
                                  },
                                  expression: "tenantInfo.scopeDescription"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "Card",
                {
                  staticClass: "ivu-mt",
                  attrs: { bordered: false, "dis-hover": "", title: "营业执照" }
                },
                [
                  _c(
                    "Row",
                    { attrs: { gutter: 32 } },
                    [
                      _c(
                        "Col",
                        { attrs: { span: "12" } },
                        [
                          _c(
                            "FormItem",
                            {
                              attrs: {
                                label: "营业执照附件",
                                prop: "bizLicenseFilePath"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "width",
                                      rawName: "v-width",
                                      value: "50%",
                                      expression: "'50%'"
                                    }
                                  ]
                                },
                                [
                                  _c(
                                    "Upload",
                                    {
                                      attrs: {
                                        action: _vm.uploadFileUrl,
                                        headers: _vm.authorization,
                                        "default-file-list":
                                          _vm.tenantInfo.defaultBizfile,
                                        format: ["jpg", "jpeg", "png", "pdf"],
                                        "on-success":
                                          _vm.handleBizLicenseSuccess,
                                        "on-remove": _vm.handleBizRemove,
                                        "before-upload": _vm.handleBlBefore,
                                        "on-preview": _vm.handleBlPreview
                                      },
                                      model: {
                                        value:
                                          _vm.tenantInfo.bizLicenseFilePath,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.tenantInfo,
                                            "bizLicenseFilePath",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "tenantInfo.bizLicenseFilePath"
                                      }
                                    },
                                    [
                                      _c(
                                        "Button",
                                        {
                                          attrs: {
                                            icon: "ios-cloud-upload-outline",
                                            "element-id":
                                              "info_bizLicenseFilePath"
                                          }
                                        },
                                        [_vm._v("上传")]
                                      ),
                                      _c(
                                        "Tooltip",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px"
                                          },
                                          attrs: {
                                            placement: "top",
                                            "max-width": "200",
                                            content:
                                              "支持的文件格式:.jpg, .jpeg, .png, .pdf!",
                                            transfer: ""
                                          }
                                        },
                                        [
                                          _c("Icon", {
                                            attrs: {
                                              type: "ios-help-circle-outline",
                                              size: "18"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: "12" } },
                        [
                          _c(
                            "FormItem",
                            {
                              attrs: {
                                prop: "bizLicenseWarnDay",
                                label: "营业执照有效期预警天数"
                              }
                            },
                            [
                              _c("Input", {
                                attrs: {
                                  type: "number",
                                  "element-id": "info_bizLicenseWarnDay"
                                },
                                model: {
                                  value: _vm.tenantInfo.bizLicenseWarnDay,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.tenantInfo,
                                      "bizLicenseWarnDay",
                                      $$v
                                    )
                                  },
                                  expression: "tenantInfo.bizLicenseWarnDay"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "Card",
                {
                  staticClass: "ivu-mt",
                  attrs: { bordered: false, "dis-hover": "", title: "许可证" }
                },
                [
                  _c(
                    "Row",
                    { attrs: { gutter: 32 } },
                    [
                      _c(
                        "Col",
                        { attrs: { span: "12" } },
                        [
                          _c(
                            "FormItem",
                            {
                              attrs: {
                                label: "许可证附件",
                                prop: "permissionFilePath",
                                rules: _vm.tenantInfoRules.permissionFilePath
                              }
                            },
                            [
                              _c(
                                "Upload",
                                {
                                  attrs: {
                                    action: _vm.uploadFileUrl,
                                    headers: _vm.authorization,
                                    format: ["jpg", "jpeg", "png", "pdf"],
                                    "default-file-list":
                                      _vm.tenantInfo.defaultPerfile,
                                    "on-success": _vm.handlePermissionSuccess,
                                    "on-remove": _vm.handlePerRemove,
                                    "before-upload": _vm.handlePerBefore,
                                    "on-preview": _vm.handlePerPreview
                                  },
                                  model: {
                                    value: _vm.tenantInfo.permissionFilePath,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.tenantInfo,
                                        "permissionFilePath",
                                        $$v
                                      )
                                    },
                                    expression: "tenantInfo.permissionFilePath"
                                  }
                                },
                                [
                                  _c(
                                    "Button",
                                    {
                                      attrs: {
                                        icon: "ios-cloud-upload-outline",
                                        "element-id": "info_permissionFilePath"
                                      }
                                    },
                                    [_vm._v("上传")]
                                  ),
                                  _c(
                                    "Tooltip",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: {
                                        placement: "top",
                                        "max-width": "200",
                                        content:
                                          "支持的文件格式:.jpg, .jpeg, .png, .pdf!",
                                        transfer: ""
                                      }
                                    },
                                    [
                                      _c("Icon", {
                                        attrs: {
                                          type: "ios-help-circle-outline",
                                          size: "18"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: "12" } },
                        [
                          _c(
                            "FormItem",
                            {
                              attrs: {
                                prop: "permissionNumber",
                                label: "许可证号",
                                rules: _vm.tenantInfoRules.permissionNumber
                              }
                            },
                            [
                              _c("Input", {
                                attrs: {
                                  "element-id": "info_permissionNumber"
                                },
                                model: {
                                  value: _vm.tenantInfo.permissionNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.tenantInfo,
                                      "permissionNumber",
                                      $$v
                                    )
                                  },
                                  expression: "tenantInfo.permissionNumber"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: "12" } },
                        [
                          _c(
                            "FormItem",
                            {
                              attrs: {
                                prop: "permissionTime",
                                label: "许可证号有效期",
                                rules: _vm.tenantInfoRules.permissionTime
                              }
                            },
                            [
                              _c("DatePicker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "element-id": "info_permissionTime",
                                  type: "daterange",
                                  "split-panels": ""
                                },
                                model: {
                                  value: _vm.tenantInfo.permissionTime,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.tenantInfo,
                                      "permissionTime",
                                      $$v
                                    )
                                  },
                                  expression: "tenantInfo.permissionTime"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: "12" } },
                        [
                          _c(
                            "FormItem",
                            {
                              attrs: {
                                prop: "permissionWarnDay",
                                label: "许可证预警天数",
                                rules: _vm.tenantInfoRules.permissionWarnDay
                              }
                            },
                            [
                              _c("Input", {
                                attrs: {
                                  type: "number",
                                  "element-id": "info_permissionWarnDay"
                                },
                                model: {
                                  value: _vm.tenantInfo.permissionWarnDay,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.tenantInfo,
                                      "permissionWarnDay",
                                      $$v
                                    )
                                  },
                                  expression: "tenantInfo.permissionWarnDay"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: "12" } },
                        [
                          _c(
                            "FormItem",
                            {
                              attrs: {
                                prop: "permissionStoreAddress",
                                label: "许可证库房地址"
                              }
                            },
                            [
                              _c("Input", {
                                attrs: {
                                  "element-id": "info_permissionStoreAddress"
                                },
                                model: {
                                  value: _vm.tenantInfo.permissionStoreAddress,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.tenantInfo,
                                      "permissionStoreAddress",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "tenantInfo.permissionStoreAddress"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "Card",
                {
                  staticClass: "ivu-mt",
                  attrs: { bordered: false, "dis-hover": "", title: "备案凭证" }
                },
                [
                  _c(
                    "Row",
                    { attrs: { gutter: 32 } },
                    [
                      _c(
                        "Col",
                        { attrs: { span: "12" } },
                        [
                          _c(
                            "FormItem",
                            {
                              attrs: {
                                label: "备案凭证附件",
                                prop: "recordFilePath",
                                rules: _vm.tenantInfoRules.recordFilePath
                              }
                            },
                            [
                              _c(
                                "Upload",
                                {
                                  attrs: {
                                    action: _vm.uploadFileUrl,
                                    headers: _vm.authorization,
                                    format: ["jpg", "jpeg", "png", "pdf"],
                                    "default-file-list":
                                      _vm.tenantInfo.defaultRecordfile,
                                    "on-success": _vm.handleRecordSuccess,
                                    "on-remove": _vm.handleRdRemove,
                                    "before-upload": _vm.handleRdBefore,
                                    "on-preview": _vm.handleRdPreview
                                  },
                                  model: {
                                    value: _vm.tenantInfo.recordFilePath,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.tenantInfo,
                                        "recordFilePath",
                                        $$v
                                      )
                                    },
                                    expression: "tenantInfo.recordFilePath"
                                  }
                                },
                                [
                                  _c(
                                    "Button",
                                    {
                                      attrs: {
                                        icon: "ios-cloud-upload-outline",
                                        "element-id": "info_recordFilePath"
                                      }
                                    },
                                    [_vm._v("上传")]
                                  ),
                                  _c(
                                    "Tooltip",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: {
                                        placement: "top",
                                        "max-width": "200",
                                        content:
                                          "支持的文件格式:.jpg, .jpeg, .png, .pdf!",
                                        transfer: ""
                                      }
                                    },
                                    [
                                      _c("Icon", {
                                        attrs: {
                                          type: "ios-help-circle-outline",
                                          size: "18"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: "12" } },
                        [
                          _c(
                            "FormItem",
                            {
                              attrs: {
                                label: "备案凭证编号",
                                prop: "recordInfo",
                                rules: _vm.tenantInfoRules.recordInfo
                              }
                            },
                            [
                              _c("Input", {
                                attrs: { "element-id": "info_recordInfo" },
                                model: {
                                  value: _vm.tenantInfo.recordInfo,
                                  callback: function($$v) {
                                    _vm.$set(_vm.tenantInfo, "recordInfo", $$v)
                                  },
                                  expression: "tenantInfo.recordInfo"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: "12" } },
                        [
                          _c(
                            "FormItem",
                            {
                              attrs: {
                                prop: "recordStoreAddress",
                                label: "备案凭证库房地址"
                              }
                            },
                            [
                              _c("Input", {
                                attrs: {
                                  "element-id": "info_recordStoreAddress"
                                },
                                model: {
                                  value: _vm.tenantInfo.recordStoreAddress,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.tenantInfo,
                                      "recordStoreAddress",
                                      $$v
                                    )
                                  },
                                  expression: "tenantInfo.recordStoreAddress"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "Card",
                {
                  staticClass: "ivu-mt",
                  attrs: { bordered: false, "dis-hover": "", title: "其他" }
                },
                [
                  _c(
                    "Row",
                    { attrs: { gutter: 32 } },
                    [
                      _c(
                        "Col",
                        { attrs: { span: "8" } },
                        [
                          _c(
                            "FormItem",
                            {
                              attrs: {
                                label: "随货同行单样张",
                                prop: "peerListFilePath"
                              }
                            },
                            [
                              _c(
                                "Upload",
                                {
                                  attrs: {
                                    action: _vm.uploadFileUrl,
                                    headers: _vm.authorization,
                                    "default-file-list":
                                      _vm.tenantInfo.defaultPeerfile,
                                    format: ["jpg", "jpeg", "png", "pdf"],
                                    "on-success": _vm.handlePeerListSuccess,
                                    "on-remove": _vm.handlePlRemove,
                                    "before-upload": _vm.handlePlBefore,
                                    "on-preview": _vm.handlePlPreview
                                  },
                                  model: {
                                    value: _vm.tenantInfo.peerListFilePath,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.tenantInfo,
                                        "peerListFilePath",
                                        $$v
                                      )
                                    },
                                    expression: "tenantInfo.peerListFilePath"
                                  }
                                },
                                [
                                  _c(
                                    "Button",
                                    {
                                      attrs: {
                                        icon: "ios-cloud-upload-outline",
                                        "element-id": "info_peerListFilePath"
                                      }
                                    },
                                    [_vm._v("上传")]
                                  ),
                                  _c(
                                    "Tooltip",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: {
                                        placement: "top",
                                        "max-width": "200",
                                        content:
                                          "支持的文件格式:.jpg, .jpeg, .png, .pdf!",
                                        transfer: ""
                                      }
                                    },
                                    [
                                      _c("Icon", {
                                        attrs: {
                                          type: "ios-help-circle-outline",
                                          size: "18"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: "8" } },
                        [
                          _c(
                            "FormItem",
                            {
                              attrs: {
                                label: "企业管理员登录名",
                                prop: "loginUser"
                              }
                            },
                            [
                              _c("Input", {
                                attrs: {
                                  prefix: "ios-contact-outline",
                                  "element-id": "info_loginUser"
                                },
                                model: {
                                  value: _vm.tenantInfo.loginUser,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.tenantInfo,
                                      "loginUser",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "tenantInfo.loginUser"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: "8" } },
                        [
                          _c(
                            "FormItem",
                            { attrs: { label: "密码", prop: "password" } },
                            [
                              _c("Input", {
                                attrs: {
                                  prefix: "ios-lock-outline",
                                  password: "",
                                  type: "password",
                                  "element-id": "info_password"
                                },
                                model: {
                                  value: _vm.tenantInfo.password,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.tenantInfo,
                                      "password",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "tenantInfo.password"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "page-sys-user-drawer-footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: "primary", "element-id": "btn_submit" },
              on: {
                click: function($event) {
                  return _vm.handleSubmitNew("userInfoForm")
                }
              }
            },
            [_vm._v("提交")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }