var pre = '/special/';
export default {
  path: '/special',
  title: '特殊业务',
  header: 'home',
  custom: 'i-icon-erp i-icon-erp-0-45',
  auth: ['501', '502', '503'],
  children: [{
    custom: 'i-icon-erp i-icon-erp-tiaobo',
    path: "".concat(pre, "lending"),
    auth: ['501'],
    title: '借入/借出'
  }, {
    custom: 'i-icon-erp i-icon-erp-zhiling',
    path: "".concat(pre, "entrust"),
    auth: ['502'],
    title: '委托指令'
  }, {
    custom: 'i-icon-erp i-icon-erp-chexiao',
    path: "".concat(pre, "retreat"),
    auth: ['503'],
    title: '委托撤回'
  }]
};