// 默认布局使用的多语言
export default {
  'zh-CN': {
    basicLayout: {
      search: {
        placeholder: '搜索...',
        cancel: '取消'
      },
      user: {
        setting: '设置',
        logOut: '退出登录'
      },
      logout: {
        confirmTitle: '退出登录确认',
        confirmContent: '您确定退出登录当前账户吗？打开的标签页和个人设置将会保存。'
      },
      tabs: {
        left: '关闭左侧',
        right: '关闭右侧',
        other: '关闭其它',
        all: '全部关闭'
      }
    }
  },
  'en-US': {
    basicLayout: {
      search: {
        placeholder: 'Search...',
        cancel: 'Cancel'
      },
      user: {
        setting: 'Setting',
        logOut: 'Log out'
      },
      logout: {
        confirmTitle: 'Logout confirmation',
        confirmContent: 'Are you sure you are logged out of your current account? Open tabs and personal settings will be saved.'
      },
      tabs: {
        left: 'Close left',
        right: 'Close right',
        other: 'Close other',
        all: 'Close all'
      }
    }
  }
};