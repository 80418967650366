var pre = '/dashboard/';
export default {
  path: '/dashboard',
  title: '主控台',
  header: 'home',
  icon: 'md-speedometer',
  children: [{
    path: "".concat(pre, "business"),
    auth: ['111'],
    title: '待审核业务'
  }, {
    path: "".concat(pre, "pending"),
    auth: ['113'],
    title: '待处理业务'
  }, {
    path: "".concat(pre, "management"),
    auth: ['112'],
    title: '首营待审核'
  }, {
    path: "".concat(pre, "warn"),
    auth: ['114'],
    title: '库存预警'
  }, {
    path: "".concat(pre, "console"),
    title: '时效提醒'
  }]
};